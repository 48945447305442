<template>
  <div class="containerDiv flex">
    <el-form ref="from" :model="form" :rules="rules" label-width="140px">
      <el-form-item label="协议code" style="width: 40%" prop="code">
        <el-input
          v-model="form.code"
          :disabled="titleType !== 2"
          maxlength="20"
          placeholder="请输入协议code"
        />
      </el-form-item>
      <el-form-item label="协议名称" style="width: 40%" prop="title">
        <el-input
          v-model="form.title"
          :disabled="titleType === 0"
          placeholder="请输入协议名称"
          maxlength="100"
        />
      </el-form-item>

      <div class="tinymc">
        <!-- <tinymc
          v-model="form.content"
          :height="500"
          :type="typeCancle"
          :is-disable="titleType === 0"
          @cancle="cancle"
        /> -->
        <Toolbar
          style="border-bottom: 1px solid #ccc"
          :editor="editor"
          :defaultConfig="toolbarConfig"
          :mode="mode"
        />
        <Editor
          style="height: 500px; overflow-y: hidden"
          v-model="form.content"
          :defaultConfig="editorConfig"
          :mode="mode"
          @onCreated="onCreated"
        />
      </div>
      <div class="btn">
        <template v-if="titleType === 0">
          <el-button @click="$router.go(-1)"> 返回 </el-button>
        </template>
        <template v-else>
          <el-button type="primary" @click="submit"> 保存 </el-button>
          <el-button @click="cancleHandle"> 取消 </el-button>
        </template>

        <!-- <el-button @click="cancleHandle">取消</el-button> -->
      </div>
    </el-form>
  </div>
</template>

<script>
// import tinymc from '@/components/tiny/index';
// import { getProtocolText, addProtocolText } from '@/api/privacy/index';
import { codeRule } from '@/utils/verificationRule';
import { Editor, Toolbar } from '@wangeditor/editor-for-vue';
export default {
  components: {
    Editor,
    Toolbar,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      editor: null,
      html: '<p>hello</p>',
      toolbarConfig: {},
      editorConfig: { placeholder: '请输入内容...' },
      mode: 'default', // or 'simple'
      form: {
        code: '',
        title: '',
        content: '',
      },
      rules: {
        code: [{ required: true, validator: codeRule, trigger: 'change' }],
        title: [{ required: true, message: '请输入协议名称', trigger: 'blur' }],
      },
      textContent: '',
      typeCancle: '',
    };
  },
  computed: {
    titleType() {
      return Number(this.$route.query.type);
    },
    id() {
      return this.$route.query.id;
    },
  },
  created() {
    Number(this.$route.query.type) !== 2
      ? this.getProtocolText()
      : (this.form.content = '');
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
    // 获取详情
    getProtocolText() {
      const params = {
        id: this.id,
      };
      this.$api
        .agreementDetail(params)
        .then((res) => {
          if (res.code === 0) {
            this.form = res.data;
          }
        })
        .catch();
    },
    // 取消
    cancleHandle() {
      this.$confirm('请确认要清空所有修改吗', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.typeCancle = 'cancle';
          this.$nextTick(() => {
            this.getProtocolText();
          });
        })
        .catch(() => {
          this.$message.info('取消');
        });
    },
    // // 选中
    // okHandle() {
    //   const tips =
    //     '请再三确认<span style="color:red">隐私政策全部内容</span>和<span style="color:red">本次更新内容</span>准确无误，提交后将直接更新到 XX健康App,所有用户打开App后将收到更新通知！！！';
    //   if (this.type === '4') {
    //     this.$confirm(`${tips}`, '提示', {
    //       confirmButtonText: '确定',
    //       cancelButtonText: '取消',
    //       type: 'warning',
    //       dangerouslyUseHTMLString: true,
    //     })
    //       .then(() => {
    //         this.submit();
    //       })
    //       .catch(() => {
    //         this.$message.info('取消');
    //       });
    //   } else {
    //     this.submit();
    //   }
    // },
    submit() {
      const { form, titleType, id } = this;
      const params = {
        ...form,
        type: titleType === 2 ? 'CREATE' : 'UPDATE',
      };
      if (titleType === 1) {
        params.id = id;
      }
      this.$api
        .agreementEdit(params)
        .then((res) => {
          if (res.code === 0) {
            this.$message.success('保存成功');
            this.$router.back();
          }
        })
        .catch();
    },
    // 组建的回调
    cancle() {
      this.typeCancle = '';
    },
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="scss" scoped>
.flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.tinymc {
  align-self: center;
  margin-top: 50px;
  margin-left: 70px;
  width: 80%;
}
.text {
  margin-top: 50px;
}
.btn {
  margin: 30px 0px;
}
.title {
  text-align: left;
}
.el-message-box__status {
  top: 20%;
}
</style>
